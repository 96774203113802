import { LegalMatterStatus } from '../@types/generated/graphql'

export interface Filter {
  status?: LegalMatterStatus | LegalMatterStatus[]
  ratings?: number | number[]
  roleFilter?: string
  assignedLawyerRef?: string | null
}

interface FilterStatus {
  label: string
  filter: Filter
}

export type FilterStatusMap = Record<string, FilterStatus>

export interface StatusLabels {
  UNASSIGNED: string
  ASSIGNING: string
  ASSIGNED: string
  CANCELED: string
  CLOSED: string
  WITHDRAWN: string
  REFERRED_IN?: string
}

export const FirmLegalMatterStatusMap: FilterStatusMap = {
  All: { label: 'All', filter: { status: undefined } },
  Referred: {
    label: 'Referred In',
    filter: { status: [LegalMatterStatus.ReferredIn] },
  },
  Unassigned: {
    label: 'Unassigned',
    filter: { status: [LegalMatterStatus.Unassigned] },
  },
  Assigning: {
    label: 'Assigning',
    filter: { status: [LegalMatterStatus.Assigning] },
  },
  Assigned: {
    label: 'Assigned',
    filter: { status: [LegalMatterStatus.Assigned] },
  },
  Completed: {
    label: 'Closed/Cancelled',
    filter: { status: [LegalMatterStatus.Canceled, LegalMatterStatus.Closed] },
  },
  Withdrawn: {
    label: 'Withdrawn',
    filter: { status: [LegalMatterStatus.Withdrawn] },
  },
}

export const LawyerLegalMatterStatusMap: FilterStatusMap = {
  All: { label: 'All My Legal Matters', filter: { status: undefined } },
  New: {
    label: 'Review Needed',
    filter: { status: [LegalMatterStatus.Assigning] },
  },
  Accepted: {
    label: 'Accepted',
    filter: { status: [LegalMatterStatus.Assigned] },
  },
  Completed: {
    label: 'Closed/Canceled',
    filter: { status: [LegalMatterStatus.Canceled, LegalMatterStatus.Closed] },
  },
  Withdrawn: {
    label: 'Withdrawn',
    filter: { status: [LegalMatterStatus.Withdrawn], assignedLawyerRef: null },
  },
  Unclaimed: {
    label: 'Unclaimed',
    filter: { status: LegalMatterStatus.Unassigned },
  },
  SharedWithMe: {
    label: 'Shared With Me',
    filter: {
      roleFilter: 'legalMatters.collaborator',
      assignedLawyerRef: null,
    },
  },
}

export const SubscriberLegalMatterStatusMap: FilterStatusMap = {
  All: { label: 'All', filter: { status: undefined } },
  New: {
    label: 'New',
    filter: {
      status: [LegalMatterStatus.Assigning, LegalMatterStatus.Unassigned],
    },
  },
  Current: {
    label: 'Current',
    filter: { status: [LegalMatterStatus.Assigned] },
  },
  Past: {
    label: 'Past',
    filter: { status: [LegalMatterStatus.Canceled, LegalMatterStatus.Closed] },
  },
}

export const SubscriberLegalMatterStatusLabels: StatusLabels = {
  UNASSIGNED: 'PENDING',
  ASSIGNING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  WITHDRAWN: 'WITHDRAWN',
}

export const LawyerLegalMatterStatusLabels: StatusLabels = {
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNING: 'ASSIGNING',
  ASSIGNED: 'ASSIGNED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  WITHDRAWN: 'WITHDRAWN',
}

export const FirmLegalMatterStatusLabels: StatusLabels = {
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNING: 'ASSIGNING',
  ASSIGNED: 'ASSIGNED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  WITHDRAWN: 'WITHDRAWN',
  REFERRED_IN: 'REFERRED IN',
}
