import Alert from '@mui/material/Alert'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import config from 'config'
import log from 'loglevel'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { routes } from 'routes'
import { DateFormatter, DateTimeFormat } from 'utils/dateUtils'
import useBillingPortalSession from 'utils/useBillingPortalSession'
import { useSubscriptions } from 'utils/useSubscriptions'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import { type SessionUrlResponse } from '../../../@types/generated/graphql'
import ItemBoundingBox from '../atoms/ItemBoundingBox'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import ConfirmationDialog from '../molecules/ConfirmationDialog'
import ListItemBoxHeading from '../molecules/ListItemBoxHeading'
import FiltersSubscriptions from '../molecules/SubscriptionFilter'
import {
  type SubscriptionsFilter,
  SubscriptionsStatusMap,
} from 'utils/subscriptionStatusMapper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { printPriceLabel } from 'utils/StringFormatter'

const SubscriptionPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { user } = useContext(UserProfileContext)
  const { subscriptionsLoading, subscriptions, hasAnyActiveSubscription } =
    useSubscriptions(user.key)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const filterParam = searchParams.get('filter') ?? 'Current'

  const filters: SubscriptionsFilter =
    SubscriptionsStatusMap[filterParam]?.filter ??
    SubscriptionsStatusMap.Current.filter

  const [
    billingPortalSessionCreate,
    { loading: billingSessionCreationInFlight, data },
  ] = useBillingPortalSession({
    onCompleted: (data) => {
      const response = data.billingPortalSessionCreate as SessionUrlResponse
      window.location.href = response.sessionUrl
    },
    onError: (error) => {
      log.warn(error)
      enqueueSnackbar(
        "It wasn't possible to initialize your dashboard session, please reload the page in a few seconds.",
        { variant: 'error' },
      )
    },
  })

  const handleRoutingToStripe = (): void => {
    void billingPortalSessionCreate({
      variables: {
        returnUrl: window.location.href,
      },
    })
  }

  const onCancelSubscription = (): void => {
    setOpenDialog(true)
  }

  const getFormattedPaidUptoDate = (
    paidUpto: Date | string,
  ): string | undefined => {
    const formattedDate = DateFormatter(
      paidUpto,
      DateTimeFormat['MMMM d, yyyy'],
    )
    if (formattedDate != null) return `Paid up to ${formattedDate}`
  }

  const handleDropdownChange = (value: string): void => {
    searchParams.set('filter', value)
    navigate(`?${searchParams.toString()}`)
  }

  if (subscriptionsLoading) {
    return <LegalMatterListLoading />
  }

  if (
    billingSessionCreationInFlight ||
    data?.billingPortalSessionCreate != null
  ) {
    return (
      <>
        <Typography variant="h6">
          Please wait while we redirect to the payment portal...
        </Typography>
        <LegalMatterListLoading />
      </>
    )
  }

  if (!hasAnyActiveSubscription) {
    return (
      <Alert variant="outlined" severity="info">
        <Typography sx={{ mb: 2 }}>
          You don't have an active subscription, you can acquire one by
          following the{' '}
          <Link
            href={routes.checkoutManageProducts.path}
            sx={{ fontSize: '16px', cursor: 'pointer' }}
          >
            {`products`}
          </Link>{' '}
          link to view the product listing.
        </Typography>
        <Typography>
          If you recently purchased a subscription, it may take a moment to
          appear here. Refresh the page in a few minutes.
        </Typography>
      </Alert>
    )
  }

  const filteredSubscriptions =
    filterParam === 'All'
      ? subscriptions
      : subscriptions.filter((s) =>
          filters.status?.includes(s?.subscriptionStatus),
        )

  return (
    <>
      <Typography variant="h1" py={4}>
        Your Legal Plans
      </Typography>
      <FiltersSubscriptions
        selectedStatus={filterParam}
        statusList={SubscriptionsStatusMap}
        onChange={handleDropdownChange}
      ></FiltersSubscriptions>
      {filteredSubscriptions?.map((subscription) => {
        const subscriptionTotal: number = subscription.products.reduce(
          (acc: number, product) => {
            if (product.price.unitAmount != null) {
              acc += product.price.unitAmount
            }
            return acc
          },
          0,
        )
        const subscriptionInterval =
          subscription.products[0].price.recurringInterval
        return (
          <ItemBoundingBox key={subscription.subscriptionId}>
            <ListItemBoxHeading
              disableMenu
              key={subscription.subscriptionId}
              title={
                <Chip
                  label={
                    <Typography variant="body1" data-chromatic="ignore">
                      {subscription.subscriptionId}
                    </Typography>
                  }
                  sx={{
                    minWidth: 300,
                  }}
                />
              }
              subtitle={getFormattedPaidUptoDate(subscription.currentPeriodEnd)}
              midContent={
                <Typography variant="h5">
                  {subscription.subscriptionStatus}
                </Typography>
              }
            />
            <hr />
            <Grid container item xs={12} px={1}>
              <Table>
                <TableBody>
                  {subscription.products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell align="right">
                        {printPriceLabel(
                          product.price.unitAmount,
                          product.price.recurringInterval,
                        )}{' '}
                        + Tax
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell variant="footer">
                      <Typography variant="h4">Total</Typography>
                    </TableCell>
                    <TableCell align="right" variant="footer">
                      <Typography variant="h4">
                        {printPriceLabel(
                          subscriptionTotal,
                          subscriptionInterval,
                        )}{' '}
                        + Tax
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </ItemBoundingBox>
        )
      })}

      <Grid container direction={'row-reverse'}>
        <Grid item py={1}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            href={routes.checkoutManageProducts.path}
            sx={{ marginTop: '24px', padding: '8px 16px' }}
          >
            Add Legal Plans
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleRoutingToStripe}
            sx={{ marginTop: '24px', padding: '8px 16px', ml: 2 }}
          >
            Manage Payment Methods
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="error"
            onClick={onCancelSubscription}
            sx={{ marginTop: '24px', padding: '8px 16px', ml: 2 }}
          >
            Cancel Legal Plans
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        title={
          <>
            <Typography variant="h1" gutterBottom>
              Cancel My Legal Plans
            </Typography>
          </>
        }
        content={
          <>
            <Typography variant="h4" gutterBottom>
              Please contact LegalFix Support to request cancellation of your
              legal plan.
            </Typography>
          </>
        }
        actions={
          <Button
            onClick={() => {
              setOpenDialog(false)
            }}
            href={`mailto:${config.legalPlansContactEmail}`}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Contact LegalFix Support
          </Button>
        }
      />
    </>
  )
}

export default SubscriptionPage
