import { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MailLockIcon from '@mui/icons-material/MailLock'
import Stack from '@mui/material/Stack'
import FormInputText from '../forms/FormInputText'
import { auth } from 'firebaseConfig'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { routes } from 'routes'

interface SignInDetails {
  email: string
}

const SignInWithEmailLink = (): JSX.Element => {
  const [formVisible, setFormVisible] = useState<boolean>(false)
  const [linkSentVisible, setLinkSentVisible] = useState<boolean>(false)
  const toggleForm = () => setFormVisible(!formVisible)

  const handleAuth: SubmitHandler<SignInDetails> = (data): void => {
    sendSignInLinkToEmail(auth, data.email, {
      url: new URL(
        `${routes.signInWithEmailLink.path}${window.location.search}`,
        window.location.href,
      ).toString(),
      handleCodeInApp: true,
    })
      .then(() => {
        window.localStorage.setItem('lfixSignInEmail', data.email)
        setLinkSentVisible(true)
        setFormVisible(false)
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })
  }

  const { register, handleSubmit, control } = useForm<SignInDetails>({
    defaultValues: { email: '' },
  })

  register('email', {
    required: { value: true, message: 'Your Email is required' },
    pattern: {
      value: /^[^@]+@[^@]+\.[^@]+$/i,
      message: 'Please enter a valid email',
    },
    maxLength: 80,
  })

  return (
    <Stack direction="column">
      <Button
        startIcon={<MailLockIcon color="info" />}
        variant="outlined"
        onClick={toggleForm}
        data-testid="btn-googleSignIn"
        sx={{ width: '100%' }}
      >
        {' '}
        Sign in with an email Link{' '}
      </Button>
      {linkSentVisible && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Check your email for the sign in link
        </Alert>
      )}
      {formVisible && (
        <Box>
          <FormInputText
            name="email"
            label="Email"
            control={control}
            sx={{ my: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit(handleAuth)}
            data-testid="btn-googleSignIn"
            sx={{ width: '100%' }}
          >
            Send me the link
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default SignInWithEmailLink
