import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { type FilterStatusMap } from 'utils/StatusMapper'
import useTestId from 'utils/useTestId'

interface FilterValues {
  value: string
  label: string
}

interface FiltersLegalMattersProps {
  selectedStatus: string
  statusList: FilterStatusMap
  onChange: (value: string) => void
  'data-testid'?: string
}

const FiltersLegalMatters: React.FC<FiltersLegalMattersProps> = ({
  selectedStatus,
  statusList,
  onChange,
  ...props
}) => {
  const testId = useTestId(props)
  const handleDropdownChange = (event: SelectChangeEvent): void => {
    const value = event.target.value
    onChange(value)
  }

  const mapNamesAndValues = (object: FilterStatusMap): FilterValues[] => {
    return Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value.label,
    }))
  }

  const mappedValues = mapNamesAndValues(statusList)

  return (
    <Grid container spacing={2} mb={2} alignItems="center">
      <Grid item>
        <Typography>Filter by Status:</Typography>
      </Grid>
      <Grid item>
        <Select
          {...testId}
          value={selectedStatus}
          onChange={handleDropdownChange}
          sx={{ minWidth: '200px' }}
        >
          {mappedValues.map((item, idx: number) => (
            <MenuItem value={item.value} key={idx}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default FiltersLegalMatters
